import React from "react";
import isEmpty from "lodash/isEmpty";
import { Contentful_PageBlocksItem, Maybe } from "graphql-types";
import SideNavAnchor from "../components/side-nav-anchor";
import { SideNavContext } from "../contexts/SideNavContext";

// Import content blocks
import BLOCKS from "./blocks";

type BlockItemProps = Contentful_PageBlocksItem & {
  __typename: string;
};

export function hasHeroBlock(block: any): boolean {
  if (!block || !block.__typename) return false;
  const blockName = block.__typename.replace(/^Contentful_Block/gi, "");
  if (blockName === "Hero" && !block.backgroundImage && !block.backgroundVideo) return false;
  return blockName === "Hero";
}

function Blocks({ pageId, blocks }: { pageId?: string; blocks: Maybe<Contentful_PageBlocksItem>[] }) {
  if (!blocks || isEmpty(blocks)) return null;

  return (
    <>
      {Object.entries(blocks).map(([key, block]) => {
        if (!block || !(block as BlockItemProps).__typename) return null;

        // For the case studies block, this has been renamed. We're manually re-mapping here.
        const typename =
          (block as BlockItemProps).__typename === "Contentful_BlockCaseStudies"
            ? "Aggregator"
            : (block as BlockItemProps).__typename;
        const blockName = typename.replace(/^Contentful_Block/gi, "");

        if (blockName === "Form") {
          const Form = BLOCKS.Form;
          return (
            <section key={key} data-type={(block as BlockItemProps).__typename} className="text-slate-400">
              <Form {...block} />
            </section>
          );
        }

        let negativeMarginTwoBlockColumn = false;
        if (
          (block as BlockItemProps).__typename === "Contentful_BlockRichText" &&
          blocks[parseInt(key) - 1] &&
          (blocks[parseInt(key) - 1] as BlockItemProps).__typename === "Contentful_BlockTwoColumn"
        )
          negativeMarginTwoBlockColumn = true;

        const DynamicComponent = BLOCKS[blockName] ?? null;
        if (!DynamicComponent) return null;

        return (
          <React.Fragment key={key}>
            <section
              data-type={(block as BlockItemProps).__typename}
              className={`text-slate-400 ${negativeMarginTwoBlockColumn ? "-mt-10 md:-mt-12 lg:-mt-24" : ""}`}
            >
              <DynamicComponent {...block} />
            </section>
          </React.Fragment>
        );
      })}
    </>
  );
}

function BlockLoader({
  pageId,
  hasSideNavigation,
  blocks,
}: {
  pageId?: string;
  hasSideNavigation: boolean;
  blocks: Maybe<Contentful_PageBlocksItem>[];
}) {
  if (!blocks || isEmpty(blocks)) return null;

  if (hasSideNavigation) {
    return (
      <SideNavContext.Provider value={true}>
        <SideNavAnchor blocks={blocks} pageId={pageId} />
      </SideNavContext.Provider>
    );
  }

  return (
    <SideNavContext.Provider value={false}>
      <Blocks blocks={blocks} pageId={pageId} />
    </SideNavContext.Provider>
  );
}

// export default BlockLoader;
export default React.memo(BlockLoader);
