import React, { useEffect, useRef, useState } from "react";
import { Contentful_BlockCircularImage } from "graphql-types";
import Image from "../image";
import gsap from "gsap";
import Maybe from "graphql/tsutils/Maybe";
import useColourway from "../../hooks/useColourway";

function CircularImage(props: Contentful_BlockCircularImage) {
  if (!props.sys) return null;
  interface State {
    isActive: boolean;
  }

  const [state, setState] = useState<State>({
    isActive: false,
  });
  const { color } = useColourway(props.colourway || null);
  const backgroundColor = `bg-${color}-400`;
  const expandWhenVisible: Maybe<boolean> = props.expand;

  function getContainerPosition(elm: any): number {
    const rect = elm.getBoundingClientRect();
    const viewHeight = rect.height;

    let percent = (rect.top / viewHeight) * -1;

    let positionY = rect.top * -1;
    positionY = positionY < 0 ? 0 : positionY;
    positionY = positionY > viewHeight ? viewHeight : positionY;

    let visiblePercent = percent + 0.25;
    visiblePercent = visiblePercent < 0.25 ? 0.25 : visiblePercent;
    visiblePercent = visiblePercent > 1 ? 1 : visiblePercent;

    if (percent > 0 && percent <= 1) {
      if (!state.isActive) {
        setState((prevState) => ({
          ...prevState,
          isActive: true,
        }));
      }
    } else {
      if (state.isActive) {
        setState((prevState) => ({
          ...prevState,
          isActive: false,
        }));
      }
    }

    return visiblePercent;
  }

  const container = useRef(null);
  const box = useRef(null);
  const image = useRef(null);
  const requestRef = useRef(0);

  function setPosition() {
    if (expandWhenVisible) {
      if (container.current && image.current) {
        const currentRadiusPercent = getContainerPosition(container.current) * 100;
        gsap.to(image.current, {
          clipPath: `circle(${currentRadiusPercent}% at 70% 50%)`,
          duration: 0.3,
        });
      }
    }
  }

  function animate() {
    setPosition();
    requestRef.current = requestAnimationFrame(animate);
  }

  function onScroll() {
    setPosition();
  }

  useEffect(() => {
    window.addEventListener("wheel", onScroll);
    requestRef.current = requestAnimationFrame(animate);
    return () => {
      gsap.killTweensOf(image.current);
      window.removeEventListener("wheel", onScroll);
      cancelAnimationFrame(requestRef.current);
    };
  });

  return (
    <div className="relative overflow-hidden z-20">
      <div className="relative">
        <div ref={container}>
          {props.image && props.image.image && (
            <Image
              image={props.image.image}
              alt={props.image.image.title ?? ""}
              maxWidth={4000}
              className={`${expandWhenVisible ? "h-screen" : ""}`}
              type={"fluid"}
              loading="lazy"
            />
          )}
        </div>
        <div>
          {expandWhenVisible && props.image && props.image.image && (
            <Image
              image={props.image.image}
              alt={props.image.image.title ?? ""}
              maxWidth={4000}
              className="h-screen"
              type={"fluid"}
              loading="lazy"
            />
          )}
        </div>
      </div>
      <div ref={box} className={`${state.isActive ? "fixed" : "absolute"} top-0 left-0 right-0 bottom-0 gpu`}>
        <div className="relative">
          <div>
            <div className={`relative left-auto right-auto bottom-auto`}>
              <div className={`absolute top-0 left-0 right-0 bottom-0 ${backgroundColor}`}></div>
              <div
                ref={image}
                className="relative"
                style={{
                  clipPath: "circle(25% at 70% 50%)",
                }}
              >
                {props.image && props.image.image && (
                  <Image
                    image={props.image.image}
                    alt={props.image.image.title ?? ""}
                    maxWidth={4000}
                    className={`${expandWhenVisible ? "h-screen" : ""}`}
                    type={"fluid"}
                    loading="lazy"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// export default CircularImage;
export default React.memo(CircularImage);
