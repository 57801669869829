import React, { useContext, useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby-plugin-intl";
import { Contentful_SiteSettings, Contentful_Article, Contentful_ContentTypeCollection } from "graphql-types";
import get from "lodash/get";
import algoliasearch from "algoliasearch/lite";
import { SiteSettingsContext } from "../../contexts/SiteSettingsContext";
import Tile from "../tile/entry";
import Heading2 from "../rich-text-elements/heading-2";
import { TileSize } from "../tile/types";

const client = algoliasearch(process.env.ALGOLIA_APP_ID ?? "", process.env.ALGOLIA_APP_SEARCH_KEY ?? "");
const index = client.initIndex(process.env.ALGOLIA_ARTICLES_INDEX ?? "");

function LatestNews(props: any) {
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState<any>([]);
  const contentTypeData = useStaticQuery(latestNewsQuery);
  const SiteSettings: Contentful_SiteSettings = useContext(SiteSettingsContext);
  const contentTypes: Contentful_ContentTypeCollection = get(contentTypeData, "contentful.contentTypeCollection");

  useEffect(() => {
    index
      .search("", {
        hitsPerPage: 3,
      })
      .then(({ hits }: { hits: any }) => {
        setLoading(false);
        setResults(hits.filter((x: any) => x !== null));
      })
      .catch(() => {});
  }, []);

  if (!results || !results.length) return null;

  return (
    <div className="container mx-auto px-6">
      <div className="flex justify-between items-center mb-14 lg:mb-24">
        {props.heading && (
          <div>
            <Heading2>{props.heading}</Heading2>
          </div>
        )}

        {SiteSettings.primaryCollection && (
          <Link to={`/${SiteSettings.primaryCollection.urlSlug}`} className="underline font-medium">
            See more
          </Link>
        )}
      </div>
      <div className="flex">
        <div className="md:grid md:grid-cols-3 lg:grid-cols-3 md:gap-6">
          {results.length > 0 &&
            results.map((result: any, key: number) => {
              const entry: Contentful_Article | any = {
                __typename: "Contentful_Article",
                sys: result.sys.id,
                title: result.title,
                urlSlug: result.urlSlug,
                publishDate: result.publishDate ?? null,
                eventDate: result.eventDate ?? null,
                cardImage:
                  (result.cardImage && {
                    title: result.cardImage.title ?? "",
                    description: result.cardImage.description ?? "",
                    contentType: result.cardImage.file.contentType ?? "",
                    fileName: result.cardImage.file.fileName ?? "",
                    url: result.cardImage.file.url ?? "",
                    width: result.cardImage.file.details.width ?? "",
                    height: result.cardImage.file.details.height ?? "",
                  }) ??
                  null,
                contentType: contentTypes.items.find((contentType: any) => contentType.sys.id === result.contentType.sys.id),
              };

              return <Tile
                entry={entry}
                key={key}
                size={TileSize.Tile}
                count={key}
              />;
            })}
        </div>
      </div>
    </div>
  );
}

// export default LatestNews;
export default React.memo(LatestNews);

const latestNewsQuery = graphql`
  query LatestNewsQuery {
    contentful {
      contentTypeCollection(order: name_ASC) {
        items {
          sys {
            id
            publishedVersion
          }
          name
          singularName
        }
      }
    }
  }
`;
