import React from "react";
import { Contentful_BlockImageColourBlock } from "graphql-types";
import useColourway from "../../hooks/useColourway";
import RichText from "../rich-text";
import Image from "../image";
import classNames from "classnames";

function ImageColourBlock(props: Contentful_BlockImageColourBlock) {
  if (!props.sys) return null;

  const { color } = useColourway(props.colourway || null);
  const darkBackgroundColor = `bg-${color}-500`;
  // const lightBackgroundColor = `bg-${color}-100`;
  const isImageFirst: boolean = props.imageFirst || false;
  const flexJustify: string = !isImageFirst ? "justify-start" : "justify-end";

  const isTransparent = props.transparent;

  return (
    <section className={`relative flex overflow-hidden flex-col lg:block ${!isTransparent && "bg-slate-100"} `}>
      <div className={`relative pointer-events-none lg:absolute lg:top-0 lg:left-0 lg:right-0 lg:bottom-0`}>
        <div className={`lg:absolute inset-0 pointer-events-none lg:w-v-3/4 ${!isImageFirst ? "ml-auto" : ""}`}>
          <div className={` ${!isTransparent && "bg-black"} pointer-events-none w-full h-full`}>
            {props.image && props.image.image && (
              <Image
                image={props.image.image}
                alt={props.image.image.title ?? ""}
                maxWidth={4000}
                type={"fluid"}
                loading="lazy"
                className={`flex h-full ${!isTransparent && "bg-black blend-screen"} `}
                objectFit="cover"
                focalPoint={props.image.focalPoint}
              />
            )}
          </div>
        </div>
      </div>
      <div className={`relative text-white lg:top-0 lg:left-0 lg:bottom:-0 lg:right-0 lg:w-full lg:h-full`}>
        <div className={`flex w-full h-full items-center lg:py-32 ${flexJustify}`}>
          <div className={`relative z-10 w-full lg:w-2/5 lg:min-w-image-colour-block ${darkBackgroundColor}`}>
            <div className="container mx-auto px-6">
              <div className="font-medium leading-none py-10 lg:p-20 lg:pt-32 lg:pb-40">
                {props.content && <RichText {...props.content} forceCurrentColor={true} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// export default ImageColourBlock;
export default React.memo(ImageColourBlock);
