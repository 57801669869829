export const findSideNavItems = (nodes: any, pageId: string) => {
  return nodes.find((node: any) => {
    if (!node?.internalPage?.sys.id) {
      return false;
    }
    if (node?.internalPage?.sys.id === pageId) {
      return true;
    } else if (node?.childrenCollection?.items?.length > 0) {
      return findSideNavItems(node?.childrenCollection.items, pageId);
    }
  });
};
