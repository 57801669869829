import {
  Contentful_BlockCaseStudiesFeaturedCollection,
  Contentful_BlockCaseStudiesFiltersCollection,
  Contentful_BlockTilesFeaturedCollection,
  Contentful_BlockTilesFiltersCollection,
  Maybe,
} from "graphql-types";
import { useMemo } from "react";
import { useArticleQuery } from "../queries/articlesQuery";
import { FilterSortBy } from "./constants";
import _ from "lodash";

interface ArticlesAggregatorFilterProps {
  filters: Maybe<Contentful_BlockCaseStudiesFiltersCollection> | undefined | Maybe<Contentful_BlockTilesFiltersCollection>;
  filterRule: "AND" | "OR";
  limit: number;
  sortBy?: Maybe<string> | undefined;
  featuredArticles:
    | Maybe<Contentful_BlockCaseStudiesFeaturedCollection>
    | Maybe<Contentful_BlockTilesFeaturedCollection>
    | undefined;
}

/**
 * Hook that uses static query with all articles and filter them on the fly.
 * @param props
 * @returns
 */
export const useArticlesAggregatorFiltered = (props: ArticlesAggregatorFilterProps) => {
  const allArticles = useArticleQuery();
  return useMemo(() => get(allArticles, props), []);
};

const get = (articleList: Array<any>, params: ArticlesAggregatorFilterProps) =>
  mergeArticles({
    limit: params.limit,
    sortBy: params.sortBy,
    featuredArticles: params.featuredArticles,
    filteredArticles: filterArticles(articleList, params.filters, params.filterRule),
  });

const mergeArticles = ({
  limit,
  sortBy,
  featuredArticles,
  filteredArticles,
}: {
  limit: number;
  sortBy: Maybe<string> | undefined;
  featuredArticles: any;
  filteredArticles: any[];
}) => {
  sortBy = !sortBy ? FilterSortBy.PUBLISHED_DATE : sortBy;
  let amountToGet = limit;
  const data = [];
  const featuredToGet = featuredArticles.items.slice(0, limit);
  amountToGet -= featuredArticles.items.length;
  data.push(...featuredToGet);

  const filtered = filteredArticles
    .filter(({ sys: { id } }) => !featuredToGet.map((featured: any) => featured.sys.id).includes(id))
    .slice(0, amountToGet);

  if (sortBy === FilterSortBy.PUBLISHED_DATE) {
    data.push(...filtered.sort((a, b) => -a.publishDate.localeCompare(b.publishDate)));
  } else if (sortBy === FilterSortBy.RANDOM) {
    data.push(..._.shuffle(filtered));
  } else {
    data.push(...filtered);
  }
  return data;
};

/**
 * @param articleList | Article List => All articles
 * @param filters | Filters => All filters from Contentful
 * @param filterRule | Filter Rule => AND | OR from Contentful
 */
const filterArticles = (
  articleList: Array<any>,
  filters: Maybe<Contentful_BlockCaseStudiesFiltersCollection> | undefined,
  filterRule: "AND" | "OR"
) => {
  if (!articleList || !articleList.length || !filters || !filters.items.length) {
    return [];
  }

  /*Get all filters*/
  const tagsIds = filters.items.filter((x: any) => x.__typename === "Contentful_Tag").map((x) => x?.sys.id);
  const sectorIds = filters.items.filter((x: any) => x.__typename === "Contentful_Sector").map((x) => x?.sys.id);
  const researchAreaIds = filters.items.filter((x: any) => x.__typename === "Contentful_ResearchArea").map((x) => x?.sys.id);
  /*Content type has a special condition which requires to include its children as we are using a multi level dropdown for Podcasts atm*/
  const contentTypeIds: Array<any> = [];
  filters.items.forEach((x: any) => {
    if (x.__typename === "Contentful_ContentType") {
      contentTypeIds.push(x?.sys.id);
      x.childrenCollection?.items?.forEach((x: any) => {
        if (x.sys.id) {
          contentTypeIds.push(x.sys.id);
        }
      });
    }
  });

  return articleList.filter((article: any) => {
    const { tags, sectors, researchAreas, contentType } = article;

    const matchTags =
      filterRule === "AND"
        ? !tagsIds.some((t: unknown) => !tags?.map((a: any) => a.contentful_id).includes(t))
        : tags?.find((z: any) => tagsIds.includes(z.contentful_id));

    const matchSectors =
      filterRule === "AND"
        ? sectorIds.length === 1
          ? sectors?.some((z: any) => sectorIds.flat(1).includes(z.contentful_id))
          : sectorIds?.every((z: any) => sectors?.map((item: any) => item?.contentful_id).includes(z))
        : sectors?.some((z: any) => sectorIds.flat(1).includes(z.contentful_id));

    // An article page can't have two content types at the same time
    const matchContentTypes = contentTypeIds.includes(contentType?.sys.id);

    const matchResearchArea =
      filterRule === "AND"
        ? !researchAreaIds.find((t: unknown) => !researchAreas?.map((a: any) => a.contentful_id).includes(t))
        : researchAreas?.find((z: any) => researchAreaIds.includes(z.contentful_id));

    return (
      (matchTags || tagsIds.length === 0) &&
      (matchSectors || sectorIds.length === 0) &&
      (matchContentTypes || contentTypeIds.length === 0) &&
      (matchResearchArea || researchAreaIds.length === 0)
    );
  });
};
