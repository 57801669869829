import React, { useEffect, useRef } from "react";
import { Contentful_BlockInfographic } from "graphql-types";
import Image from "../image";
import classNames from "classnames";

function Infographic(props: Contentful_BlockInfographic & { sidebar?: boolean }) {
  if (!props.sys) return null;
  const isResponsiveImageAvailable = props.secondaryImage ? true : false;

  const staticContainerRef = useRef(null);
  const responsiveContainerRef = useRef(null);

  useEffect(() => {
    // window.addEventListener("resize", onResize);

    let request: any = null;

    const setContainerPosition = () => {
      const staticContainer: any = staticContainerRef.current;
      const responsiveContainer: any = responsiveContainerRef.current;

      if (staticContainer && responsiveContainer) {
        const staticRect = staticContainer.getBoundingClientRect();
        const responsiveRect = responsiveContainer.getBoundingClientRect();

        const responsiveContainerPosition: number = -(responsiveRect.width / 2 - staticRect.width / 2);

        if (parseInt(responsiveContainer.style.left) != Math.floor(responsiveContainerPosition)) {
          responsiveContainer.style.left = `${Math.floor(responsiveContainerPosition)}px`;
        }
      }
    };

    const onAnimationRequest = () => {
      setContainerPosition();
      request = window.requestAnimationFrame(onAnimationRequest);
    };

    request = window.requestAnimationFrame(onAnimationRequest);
    window.addEventListener("resize", setContainerPosition);
    return () => {
      window.removeEventListener("resize", setContainerPosition);
      window.cancelAnimationFrame(request);
    };
  }, []);
  if (props.sidebar) {
    return (
      <section className="container mx-auto py-6">
        <>
          {props.primaryImage && (
            <>
              <Image
                image={props.primaryImage}
                alt={props.primaryImage.title ?? ""}
                className={classNames("w-full h-auto", {
                  "md:hidden": isResponsiveImageAvailable,
                })}
                maxWidth={4000}
                type={"fluid"}
                loading="eager"
              />
            </>
          )}
          {props.secondaryImage && (
            <>
              <Image
                image={props.secondaryImage}
                alt={props.secondaryImage.title ?? ""}
                className={classNames("w-full h-auto hidden", {
                  "md:block": isResponsiveImageAvailable,
                })}
                maxWidth={4000}
                type={"fluid"}
                loading="eager"
              />
            </>
          )}
        </>
      </section>
    );
  }

  return (
    <section className="container mx-auto py-6">
      <div ref={staticContainerRef} className="relative w-full"></div>
      <div ref={responsiveContainerRef} className="relative w-full md:w-60vw md:min-w-600 md:max-w-1180">
        {props.primaryImage && (
          <>
            <Image
              image={props.primaryImage}
              alt={props.primaryImage.title ?? ""}
              className={classNames("w-full h-auto", {
                "md:hidden": isResponsiveImageAvailable,
              })}
              maxWidth={4000}
              type={"fluid"}
              loading="eager"
            />
          </>
        )}
        {props.secondaryImage && (
          <>
            <Image
              image={props.secondaryImage}
              alt={props.secondaryImage.title ?? ""}
              className={classNames("w-full h-auto hidden", {
                "md:block": isResponsiveImageAvailable,
              })}
              maxWidth={4000}
              type={"fluid"}
              loading="eager"
            />
          </>
        )}
      </div>
    </section>
  );
}

// export default Tiles;
export default React.memo(Infographic);
