import React, { useState, useEffect } from "react";
import { Contentful_BlockInPageNavigation, Contentful_Link } from "graphql-types";
import classnames from "classnames";
import SVG from "react-inlinesvg";
import Link from "../link";
import Heading2 from "../rich-text-elements/heading-2";

function InPageNavigationItem({ isActive, props }: { isActive: boolean; props: Contentful_Link }) {
  const ClassName = classnames([
    "border-2 border-slate-300 rounded-full font-semibold text-center py-3 px-6 md:border-0 md:border-b-2 md:rounded-none md:p-2 flex-grow transition duration-500 border-b-2 md:border-slate-200 p-2 md:font-medium md:text-lg md:text-slate-300 hover:border-slate-300",
    {
      "md:border-slate-400 hover:border-slate-400 md:text-slate-400": isActive,
    },
  ]);

  return (
    <div className={ClassName}>
      <Link {...props} />
    </div>
  );
}

function InPageNavigation(props: Contentful_BlockInPageNavigation) {
  if (!props.sys) return null;

  const isSSR = typeof document === "undefined";

  function GetActiveItem(): Contentful_Link | null {
    if (isSSR) return null;
    // Get url slug and then find in the nav lis
    const item =
      props.navigationItemsCollection &&
      props.navigationItemsCollection?.items.find((item, key) => {
        if (!item) return null;
        return window.location.pathname.endsWith(item.internalPage?.urlSlug ?? "");
      });

    return item ?? null;
  }

  const [isOpen, setIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState<Contentful_Link | null>();

  useEffect(() => {
    setCurrentItem(GetActiveItem());
  }, []);

  return (
    <div
      className={`container mx-auto px-6 md:px-0 py-10 my-10 text-slate-400 ${
        isOpen ? "bg-slate-200 bg-opacity-40 md:bg-transparent" : ""
      }`}
    >
      {isOpen && props.heading && <Heading2 className="text-center md:hidden">{props.heading}</Heading2>}
      <div
        className={`${
          isOpen ? "hidden" : "md:hidden"
        } border-2 border-slate-300 rounded-full font-semibold text-center py-3 px-6 flex space-x-2 justify-center items-center`}
        onClick={() => setIsOpen(true)}
      >
        <span>{currentItem && currentItem.label}</span>
        <SVG src={`/images/icons/arrow-down.svg`} width="14" height="8" title="" className="" cacheRequests={true} />
      </div>
      <div
        className={`${
          isOpen ? "opacity-100 h-auto" : "opacity-0 h-0 md:opacity-100 md:h-auto"
        } transition-all duration-1000 space-y-4 overflow-hidden md:flex md:justify-around md:space-y-0`}
      >
        {props.navigationItemsCollection &&
          props.navigationItemsCollection?.items.map((item, key) => {
            if (!item) return null;
            return <InPageNavigationItem props={item} key={key} isActive={item.sys.id === currentItem?.sys.id} />;
          })}
      </div>
    </div>
  );
}

// export default InPageNavigation;
export default React.memo(InPageNavigation);
