import React from "react";
import { Contentful_BlockRawHtml } from "graphql-types";

function RawHtml(props: Contentful_BlockRawHtml) {
  const { html } = props;
  if (!html) return null;

  return <div dangerouslySetInnerHTML={{ __html: html }} />;
}

export default RawHtml;
