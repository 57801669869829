import React from "react";
import classnames from "classnames";
import useColourway from "../../hooks/useColourway";
import RichText from "../rich-text";
import { Contentful_BlockTwoColumn } from "graphql-types";
import { BlockSpaceJson, SpacerDefaultProps, translateBlockSpacing } from "../../utils/translate-cf-spacing";

function TwoColumn(props: Contentful_BlockTwoColumn) {
  if (!props.sys) return null;
  const { backgroundColor } = useColourway(props.colourway || null);

  const topSpaceDefault: SpacerDefaultProps = {
    sm: 6,
    md: 12,
    lg: 24,
  };

  const bottomSpaceDefault: SpacerDefaultProps = {
    sm: 6,
    md: 12,
    lg: 24,
  };

  const topSpaceClasses = translateBlockSpacing("top", props.topSpace as BlockSpaceJson, topSpaceDefault);
  const bottomSpaceClasses = translateBlockSpacing("bottom", props.bottomSpace as BlockSpaceJson, bottomSpaceDefault);

  return (
    <section
      className={classnames([
        "flex",
        "items-center",
        "text-slate-400",
        topSpaceClasses,
        bottomSpaceClasses,
        backgroundColor,
      ])}
    >
      <div className="container mx-auto px-6">
        <div className="md:grid md:grid-cols-2 md:gap-10 lg:gap-28 xl:gap-32 text-xl">
          <div className="mb-6 md:mb-0">{props.left && <RichText {...props.left} />}</div>
          <div>{props.right && <RichText {...props.right} forceCurrentColor={true} />}</div>
        </div>
      </div>
    </section>
  );
}

// export default TwoColumn;
export default React.memo(TwoColumn);
