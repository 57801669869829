import React, { useEffect, useState } from "react";
import { Contentful_BlockTiles } from "graphql-types";
import Heading2 from "../rich-text-elements/heading-2";
import Button from "../button";
import Tile from "../tile/entry";
import { useArticlesAggregatorFiltered } from "../../utils/article-aggregator-filters";

const Heading = React.memo(function (props: Contentful_BlockTiles) {
  if (!props.heading) return null;
  return (
    <>
      <div className="text-slate-400">
        <Heading2>{props.heading}</Heading2>
      </div>
      <div className="hidden md:block">
        <CallToAction {...props} />
      </div>
    </>
  );
});

const CallToAction = React.memo(function (props: Contentful_BlockTiles) {
  if (!props.callToAction) return null;
  return <Button {...props.callToAction} />;
});

function Tiles(props: Contentful_BlockTiles) {
  if (!props.sys) return null;

  const isHeadingVisible = props.invisibleHeading === false ? false : true;
  const filterRule = !props.filterRule ? "OR" : (props.filterRule as "AND" | "OR");
  const tiles = useArticlesAggregatorFiltered({
    featuredArticles: props.featuredCollection,
    filterRule: filterRule,
    limit: props.limit === "all" ? 60 : props.limit ? parseInt(props.limit) : 6,
    filters: props.filtersCollection,
  });

  return (
    <section className="container mx-auto px-6 py-4 md:py-14">
      {isHeadingVisible && (
        <div className="flex flex-no-wrap items-center justify-between md:mb-6">
          <Heading {...props} />
        </div>
      )}
      <section className="pt-4 md:grid md:grid-cols-3 md:gap-6">
        {tiles.map((tile, key) => (
          <Tile entry={tile} key={key} count={key} />
        ))}
      </section>
      <div className="md:hidden text-center pb-6">
        <CallToAction {...props} />
      </div>
    </section>
  );
}

// export default Tiles;
export default React.memo(Tiles);
